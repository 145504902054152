<template>
  <div class="cityLocation">
    <breadcrumb icon="el-icon-s-grid"></breadcrumb>
    <div class="blockintro">
      <div v-if="nodata" class="nodata"> {{ message }} </div>
      <html-text v-else :introData="introData" @selectall="selectall"></html-text>
    </div>

  </div>
</template>
<script>
import Breadcrumb from '../components/common/Breadcrumb.vue'
import HtmlText from '../components/cityreserch/HtmlText.vue'
import { intro } from '../api/cityresearch.js'
export default {
  data() {
    return {
      nodata: false,
      message: '',
      introData: {}
    }
  },
  components: { Breadcrumb, HtmlText },
  computed: {
    cname: {
      get() {
        let a = this.$store.state.cname
        return a;
      },
      set(v) {
        this.$store.state.cname = v
      }
    },
    //当前选择的id
    block: {
      get() {
        // alert(this.$store.state.block)
        return this.$store.state.block
      }
    },
 

  },
  watch: {
    block(newv, oldv) {
      this.getIntro()
    }
  },
  mounted() {
    console.log(this.$route.name)

    this.getIntro()
  },
  methods: {

    getIntro() {
      var that = this;
      if (this.block) {
        intro({ city_plate_id: this.block }).then(res => {
          if (res.code === 1001) {
            this.nodata = true
            this.message = res.message
            this.introData = {}
          } else {
            console.log('111', res.data)
            this.nodata = false
            this.introData = that.handledata(res.data)
          }
        })
      }


    },
    //计算是否全选
    changeStatus() {
      const allselector = this.introData.pics.every(item => {
        return item.status
      })

    },
    //全选
    selectall(obj) {
      this.introData.pics = this.introData.pics.map(item => {
        item.status = obj.status
        return item
      })

    },

    handledata(obj) {
      obj.pics = obj.pics.map(i => {
        i.status = false;
        return i
      })
      return obj;

    },

    selectedCid(id) {

      // if(this.$store.state.currentCity.value == value){
      //   let x =  this.$store.state.currentCity.label
      //   this.cname = x;
      // }else{
      //   const v =  this.$store.state.currentCity.children.filter(v=>{
      //     if(v.value == value){
      //       return v
      //     }
      //   })
      //   this.cname = v[0].label
      // }

    }
  }
}
</script>
<style lang="scss">
.cityLocation {
  width: 100%;
  min-height: calc(100vh - 100px);
  color: #333;
}

.areaMap {
  width: 98%;
  margin: 1%;
  height: calc(100vh - 244px);
  background: #ddd;
}

.swipercontainer {
  position: relative;
  bottom: 0;
  right: 0;
  left: 0;
  top: -100%;
  z-index: 10;
}
.blockintro{
  margin-top:20px;
}
</style>  